<template>
  <v-main style="min-height: 800px;">
    <Header  />

    <v-container fluid class="ma-0 pa-0" style="min-height: 800px;">
      <slot />
    </v-container>

    <Footer v-if="!loader" />
  </v-main>

</template>

<script>
import Header from "@/components/Widget/Header.vue";
import Footer from "@/components/Widget/Footer.vue";
import {createNamespacedHelpers} from "vuex";

export default {
  name: "DefaultLayout",
  components:{Header,Footer},
  computed:{
    ...createNamespacedHelpers("Loader").mapGetters([
      "loader"
    ]),
    showFooter(){
      return false;
    },
    showHeader(){
      return this.$route.path != '/' && this.$route.path != '/404';
    },
    show(){
      return false;
    },
  }
}
</script>

<style scoped>

</style>