<template>
  <div
    :class="[
      this.checkBreakfastMode
        ? 'v-card border white rounded-lg pt-0 mt-0 mb-2'
        : 'pt-4'
    ]"
  >
    <v-alert
      v-if="checkBreakfastMode"
      icon="mdi-calendar"
      type="info"
      color="primary"
      class="ma-0"
      dismissible
      @input="removeDay"
    >
      {{ this.day }}
    </v-alert>
    <!--start card empty message-->
    <v-sheet
      v-show="isCartEmpty"
      width="70%"
      color="transparent"
      class="mt-10 mb-14 grey--text text-center mx-auto justify-center"
    >
      <v-icon large>mdi-shopping</v-icon>
      <p class="caption">
        {{$t("generic.lang_addItemMsg")}}
      </p>
    </v-sheet>
    <!--end card empty message-->
    <v-sheet
      v-show="!isCartEmpty"
      v-for="(order, index) in !checkBreakfastMode
        ? cart
        : cart.filter(item => item.date === day)"
      :key="index"
      color="transparent"
      :class="[
        checkBreakfastMode
          ? 'rounded-lg my-3 pa-2 d-flex justify-space-between'
          : 'rounded-lg my-3 pa-0 d-flex justify-space-between'
      ]"
    >
      <div class="d-flex">
        <div class="text-left justify-start">
          <span class="font-weight-regular body-2 accent--text">
            {{ order.amount }}x
          </span>
        </div>

        <div class="ml-3">
          <div class="text-left justify-start">
            <span class="font-weight-bold caption" style="z-index: 1;">
              {{ order.name }}
            </span>
          </div>

          <!--start selected extras-->
          <div
            v-if="Array.isArray(order.garnish) && order.garnish.length > 0"
            class="text-left justify-start small"
            style="margin-left: -12px;"
          >
            <ul>
              <li
                v-for="(ext, index) in order.garnish"
                :key="ext.id + '-' + index"
              >
                <strong class="primary--text">{{ ext.amount }}</strong>
                x {{ ext.name }} (<strong class="primary--text">
                  {{ (ext.amount * ext.sell_price) | currency }} </strong
                >)
              </li>
            </ul>
          </div>
          <!--end selected extras-->

          <!--start cancelItem and openNote button-->
          <div
            class="d-flex text-left justify-start"
            style="margin-left: -10px;"
          >
            <v-btn @click="deleteItem(order)" icon small color="black">
              <v-icon small>mdi-trash-can-outline</v-icon>
            </v-btn>

            <v-btn @click="openNote(order)" icon small color="black">
              <v-icon small>mdi-comment-processing-outline</v-icon>
              <!--<font-awesome-icon :icon="['fal', 'CoffeeTogo']" size="2x" />-->
            </v-btn>
          </div>
          <!--end cancelItem and openNote button-->
        </div>
      </div>

      <!--start minus and plus buttons and amount-->
      <div class="mx-1">
        <strong class="font-weight-bold caption" style="z-index: 1;">
          {{ itemTotalPrice(order) | currency }}
        </strong>
        <strong
          v-if="order.discount"
          class="font-weight-bold caption error--text"
          style="z-index: 1;"
        >
          {{ (-order.discount).toFixed(2) + "%" }}
        </strong>

        <div class="d-flex w-25">
          <v-btn
            class="mr-1"
            color="accent"
            icon
            outlined
            rounded
            x-small
            :disabled="order.amount <= 1"
            @click="removeQTY(order)"
          >
            <v-icon class="pa-0 px-0" x-small>mdi-minus</v-icon>
          </v-btn>

          <v-btn
            color="accent"
            icon
            outlined
            rounded
            x-small
            @click="addQTY(order)"
          >
            <v-icon x-small>mdi-plus</v-icon>
          </v-btn>
        </div>
      </div>
      <!--end minus and plus buttons and amount-->
    </v-sheet>
    <!--start note dialog-->
    <item-note-dialog
      v-if="!isCartEmpty"
      v-model="note_dialog"
      :dialog="note_dialog"
    />
    <!--end note dialog-->
  </div>
</template>

<script>
/*import plugins*/
import { createNamespacedHelpers } from "vuex";
import moment from "moment";

//import components
import ItemNoteDialog from "@/components/Card/ItemNoteDialog";
import { isBreakfast } from "../../helpers/breakfast";

/*import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faCoffeeTogo, faPersonCarry } from "@fortawesome/pro-light-svg-icons";
library.add(faCoffeeTogo, faPersonCarry);*/

export default {
  name: "ShoppingCartOrdersCardPc",
  props: {
    day: {
      type: String,
      validator: val => moment(val, "DD.MM.YYYY").isValid()
    }
  },

  components: { ItemNoteDialog /*"font-awesome-icon": FontAwesomeIcon*/ },

  data: () => ({
    note_dialog: false
  }),

  computed: {
    /*reference Order getters*/
    ...createNamespacedHelpers("Order").mapGetters([
      "cart",
      "isCartEmpty",
      "itemTotalPrice"
    ]),
    checkBreakfastMode() {
      return isBreakfast();
    }
  },

  methods: {
    ...createNamespacedHelpers("Order").mapMutations([
      "addQTY",
      "removeQTY",
      "deleteItem",
      "setSelectedItem",
      "replaceCart"
    ]),

    removeDay() {
      if (!this.isCartEmpty) {
        this.replaceCart([
          ...[...this.cart].filter(item => item.date !== this.day)
        ]);
      }
    },
    /*open note dialog*/
    openNote(item) {
      this.setSelectedItem(item);
      this.note_dialog = true;
    }

    /*saveFreetext() {
      this.$store
        .dispatch("cart/setItemFreetext", {
          item: this.cartItem,
          freetext: this.freetext,
        })
        .then(() => {
          this.freetext = "";
          this.editFreetext = false;
        });
    },*/
  }
};
</script>
