<template>
  <!--todo:HI please remove style attr and use class or id to apply this style -->
  <!--Start Shopping Cart Content-->
  <v-card tile elevation="0" color="grey lighten-3">
    <v-container fluid class="pa-1">
      <v-container>
        <v-row>
          <v-col cols="12" align-self="center" align="center" class="px-0">
            <v-btn-toggle dense>
              <v-btn
                style="border-width: 1px !important;"
                :disabled="!offersDelivery || checkBreakfastMode"
                :color="isDelivery ? 'success' : ''"
                @click="toggleService(true)"
                :dark="isDelivery"
                height="50"
              >
                <v-badge
                  v-if="offersDelivery"
                  :value="!!!deliveryInfo"
                  color="error"
                  left
                >
                  <template v-slot:badge>
                    <v-icon color="white" size="15"
                      >mdi-map-marker-question-outline
                    </v-icon>
                  </template>
                  <font-awesome-icon
                    :icon="['fal', 'biking-mountain']"
                    :style="{
                      color: isDelivery ? 'white' : 'black',
                      fontSize: '18px'
                    }"
                  ></font-awesome-icon>
                  <span :style="{ color: isDelivery ? 'white' : 'black' }">{{
                    $t("delivery.lang_delivery")
                  }}</span>
                </v-badge>
                <template v-else>
                  <font-awesome-icon
                    :icon="['fal', 'biking-mountain']"
                    :style="{ color: 'black', fontSize: '18px' }"
                  ></font-awesome-icon>
                  <span :style="{ color: 'black' }">{{
                    $t("delivery.lang_delivery")
                  }}</span>
                </template>
              </v-btn>

              <v-btn
                style="border-width: 1px !important;"
                :disabled="!offersPickUp"
                :color="!isDelivery ? 'success' : ''"
                :dark="!isDelivery"
                height="50"
                @click="toggleService(false)"
              >
                <font-awesome-icon
                  :icon="['fal', 'burger-soda']"
                  :style="{
                    color: !isDelivery ? 'white' : 'black',
                    fontSize: '18px'
                  }"
                ></font-awesome-icon>
                <span :style="{ color: !isDelivery ? 'white' : 'black' }">{{
                  $t("delivery.lang_pickup")
                }}</span>
              </v-btn>
            </v-btn-toggle>
          </v-col>
        </v-row>
      </v-container>

      <div class="mx-4" style="margin-bottom: -12px; margin-top: -16px;">
        <v-divider />
      </div>

      <!--start head of the card-->
      <v-toolbar
        elevation="0"
        color="grey lighten-3"
        class="d-flex px-2 justify-center"
        style="margin-bottom: -10px;"
      >
        <div>
          <h6 class="pl-4 pt-2">
            {{ $t("delivery.lang_cart") }}
          </h6>
        </div>

        <div>
          <v-badge
            :content="NProductSelect"
            :value="NProductSelect"
            color="accent"
            overlap
            bottom
            bordered
          >
            <v-icon class="mt-0" size="22px" color="black" dark>
              mdi-shopping-outline
            </v-icon>
          </v-badge>
        </div>
      </v-toolbar>
      <div v-if="!isDelivery">
        <v-sheet
          v-if="shop"
          tile
          elevation="0"
          width="100%"
          color="grey lighten-3"
          class="px-2 pt-7 pb-4 mx-auto text-center d-inline-block"
        >
          <div class="font-weight-bold text-body-2 mb-4">
            {{ $t("delivery.lang_pickupAddressIs") }}
            {{
              shop &&
                shop.street +
                  " " +
                  shop.streetNumber +
                  " " +
                  shop.zip +
                  " " +
                  shop.city
            }}
          </div>
        </v-sheet>
      </div>
      <div class="mx-5" style="margin-top: -10px;">
        <v-divider />
      </div>
      <!--end head of the card-->

      <!--Start Shopping Cart Orders-->
      <div v-if="checkBreakfastMode" class="mx-2">
        <shopping-cart-orders-card-pc
          v-for="day in days"
          :key="day"
          :day="day"
        />
      </div>
      <div v-else class="mx-2">
        <shopping-cart-orders-card-pc />
      </div>
      <!--End Shopping Cart Orders-->

      <div class="px-3 pb-16 mt-3 mx-auto text-center">
        <div>
          <v-divider />
        </div>

        <div class="d-flex" v-if="isDelivery">
          <span class="caption grey--text">{{
            $t("delivery.lang_subtotal")
          }}</span>
          <v-spacer></v-spacer>
            <TotalWrapper :total="cartTotalSumWithVoucher" class="caption grey--text"/>
        </div>

        <div class="d-flex" v-if="isDelivery">
          <span class="caption grey--text">{{
            $t("delivery.lang_deliveryCosts")
          }}</span>
          <v-spacer></v-spacer>
          <span class="caption grey--text" v-if="deliveryInfo">
            {{
              deliveryInfo.deliveryCosts
                ? deliveryInfo.deliveryCosts
                : "free" | currency
            }}
          </span>
          <span v-else class="caption">
            {{ $t("delivery.lang_pleaseSet") }}
          </span>
        </div>

        <div class="d-flex">
          <span class="caption font-weight-bold black--text">{{
            $t("delivery.lang_total")
          }}</span>
          <v-spacer></v-spacer>
            <TotalWrapper :total="totalSumWithVoucher" class="caption font-weight-bold black--text"/>
        </div>

        <div>
          <v-divider />
        </div>

        <p v-if="isDelivery" class="mt-4 small grey--text">
          {{ $t("delivery.lang_minimumOrderValue") + " "
          }}{{
            deliveryInfo ? deliveryInfo.minimumOrderValue : 0 | currency
          }}.{{ " " + $t("delivery.lang_reachMinimumOrderValue") }}
        </p>
      </div>
      <!--<v-sheet height="100px" color="transparent" />-->
    </v-container>
    <v-divider class="ma-0" />
    <v-sheet
      tile
      elevation="2"
      width="100%"
      color="grey lighten-3"
      style="position: fixed;bottom: 0;width: 100%;display: flex;justify-content: center;"
    >
      <v-btn
        v-if="canOrder"
        @click="redirectToCheckout()"
        width="80%"
        height="65px"
        color="accent"
        dark
        style="margin-left: 5% !important;"
        class="px-4 my-2 text-capitalize"
      >
        <div class="d-flex justify-center">
          <h4 class="font-weight-regular white--text pt-1 pr-2">
            {{ $t("delivery.lang_order") }}
          </h4>
        </div>
      </v-btn>
    </v-sheet>
  </v-card>
  <!--End Shopping Cart Content-->
</template>

<script>
/*import plugins*/
import { createNamespacedHelpers } from "vuex";

/*import component*/
import ShoppingCartOrdersCardPc from "@/components/Card/ShoppingCartOrdersCardPc.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { isBreakfast } from "../../helpers/breakfast";
import TotalWrapper from "@/components/Widget/TotalWrapper.vue";

import {
  faBikingMountain,
  faBurgerSoda
} from "@fortawesome/pro-light-svg-icons";

library.add({
  faBurgerSoda,
  faBikingMountain
});
export default {
  name: "ShoppingCartPc",

  components: {
    ShoppingCartOrdersCardPc,
    "font-awesome-icon": FontAwesomeIcon,
    TotalWrapper
  },

  props: {
    dialog: Boolean
  },

  data() {
    return {
      hintText: false,
      addresses: []
    };
  },

  mounted() {
    if (this.isLoggedIn && this.isDelivery) {
      this.getUserAddress();
    }

    this.$nextTick(() => {
      //enable pickup by default for breakfast mode
      if (isBreakfast()) {
        this.toggleService(false);
        this.$root.$emit("toggleDeliveryAreaDialog", false);
        this.setDelivery(false);
      }
    });
  },

  methods: {
    ...createNamespacedHelpers("Config").mapMutations(["setDelivery"]),
    ...createNamespacedHelpers("Order").mapMutations(["setItemPrices"]),

    ...createNamespacedHelpers("Config").mapMutations(["setDeliveryInfo"]),
    //Redirect To Checkout Page
    redirectToCheckout() {
      if (!this.canOrder) {
        this.$root.$emit("toggleDeliveryAreaDialog", false);
        return;
      }

      if (this.isDelivery) {
        if (this.deliveryInfo) {
          if (
            this.cartTotalSum >= this.deliveryInfo.minimumOrderValue &&
            !this.isCartEmpty
          ) {
            this.$router.push("/checkout-order");
            this.$root.$emit("toggleDeliveryAreaDialog", false);
            return;
          } else {
            this.$swal({
              title: this.$t("generic.lang_Warning"),
              icon: "warning",
              text: this.$t("delivery.lang_minimumOrderValueNotReached")
            });
            this.$root.$emit("toggleDeliveryAreaDialog", false);
          }
        } else {
          this.$root.$emit("toggleDeliveryAreaDialog", true);
          return;
        }
        this.$root.$emit("toggleDeliveryAreaDialog", false);
      } else {
        this.$router.push("/checkout-order");
        this.$root.$emit("toggleDeliveryAreaDialog", false);
      }
    },

    toggleService(status) {
      if (this.$route.path !== "/") {
        if (status) {
          if (!this.offersDelivery) {
            this.$swal({
              title: this.$t("generic.lang_Warning"),
              icon: "warning",
              text: this.$t("delivery.lang_cantAcceptDelivery")
            });
            return;
          }
        } else {
          if (!this.offersPickUp) {
            this.$swal({
              title: this.$t("generic.lang_Warning"),
              icon: "warning",
              text: this.$t("delivery.lang_cantAcceptPickup")
            });
            return;
          }
        }
      }
      if (this.$route.path !== "/") {
        if (!(!this.isCartEmpty && !this.maintenanceMode)) {
          this.$router.push("/");
        }

        if (this.isDelivery) {
          if (this.deliveryInfo) {
            if (
              !(
                this.cartTotalSum >= this.deliveryInfo.minimumOrderValue &&
                !this.isCartEmpty
              )
            ) {
              this.$router.push("/");
            }
          }
        }
      }

      if (status) {
        if (!this.offersDelivery) {
          this.$swal({
            title: this.$t("generic.lang_Warning"),
            icon: "warning",
            text: this.$t("delivery.lang_cantAcceptDelivery")
          });
        }
      } else {
        if (!this.offersPickUp) {
          this.$swal({
            title: this.$t("generic.lang_Warning"),
            icon: "warning",
            text: this.$t("delivery.lang_cantAcceptPickup")
          });
        }
      }

      if (this.isDelivery && status) {
        this.$root.$emit("toggleDeliveryAreaDialog", true);
      }

      this.setDelivery(status);
      this.setItemPrices(status);
    },

    getUserAddress() {
      this.addresses = [];
      this.axios
        .get("localbee/v2/account/address")
        .then(res => {
          if (res.status === 200) {
            let defaultAddress = res.data.data.find(
              address => address.default === 1
            );
            let area = null;
            if (defaultAddress && !this.deliveryInfo) {
              area = this.deliveryAreas.find(
                searchArea => searchArea.zip === defaultAddress.zip
              );
              if (area) this.setDeliveryInfo(area);
              else
                this.$swal({
                  title: this.$t("generic.lang_Warning"),
                  icon: "warning",
                  text: this.$t("delivery.lang_cantDeliverToZipCode")
                });
            }
          } else {
          }
        })
        .catch(err => {
          if (err.status === 500) {
            this.$swal({
              title: this.$t('delivery.lang_error'),
              icon: "error",
              text: this.$t('delivery.lang_somethingWentWrongPleaseTryAgainLater')
            });
            return;
          }
        })
        .finally(() => {});
    },

    setDeliverArea(deliveryArea) {
      this.setDeliveryInfo(deliveryArea);
    }
  },

  watch: {
    isDelivery(val) {
      if (this.isLoggedIn && this.isDelivery) {
        this.getUserAddress();
      }
    }
  },

  computed: {
    ...createNamespacedHelpers("Order").mapGetters([
      "cart",
      "isCartEmpty",
      "cartTotalSum",
      "totalSum",
      "cartTotalSumWithVoucher",
      "totalSumWithVoucher",
    ]),

    ...createNamespacedHelpers("auth").mapGetters(["isLoggedIn"]),

    ...createNamespacedHelpers("Config").mapGetters([
      "maintenanceMode",
      "offersDelivery",
      "offersPickUp",
      "isDelivery",
      "shop",
      "deliveryInfo",
      "deliveryAreas",
      "enableOrderScheduling",
      "isStillOpen"
    ]),

    checkBreakfastMode() {
      return isBreakfast();
    },
    days() {
      if (!this.isCartEmpty && this.checkBreakfastMode) {
        const days = [...this.cart].map(({ date }) => date);
        return days.filter((day, index) => days.indexOf(day) === index);
      }

      return [];
    },
    canOrder() {
      let can =
        !this.isCartEmpty &&
        !this.maintenanceMode &&
        (this.isStillOpen || this.enableOrderScheduling) &&
        this.$route.path === "/" &&
        (this.isDelivery ? this.offersDelivery : this.offersPickUp);
      return can;
    },

    /*get number of selected items*/
    NProductSelect() {
      return this.cart.length;
    }
  }
};
</script>
