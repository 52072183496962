<template>
  <div v-scroll="scrolled">
    <!--start navbar-->
    <v-app-bar
      :app="isFixedTop"
      clipped-right
      dark
      flat
      height="50px"
      color="primary"
      style="z-index: 10;"
    >
      <div
        @click="
          $route.path == '/'
            ? $router.push('/')
            : $router.push('/')
        "
        style="cursor: pointer;"
      >
        <v-img
          src="@/assets/LocalBee white.svg"
          alt="LocalBee Logo"
          width="40px"
        />
      </div>

      <v-spacer />

      <v-speed-dial v-model="fab" direction="bottom" class="px-2" transition="slide-y-transition">
        <template v-slot:activator>
          <v-btn
            v-if="$i18n.locale === 'de'"
            v-model="fab"
            fab
            icon
            dark
            ripple
            class="ma-0"
            small
            right
            depressed
            elevation="0"
          >
            <v-avatar size="28">
              <flag style="font-size: 60px" iso="de" />
            </v-avatar>
          </v-btn>
          <v-btn
            v-if="$i18n.locale === 'en'"
            v-model="fab"
            fab
            icon
            dark
            class="ma-0"
            small
            ripple
            right
            depressed
            elevation="0"
          >
            <v-avatar size="28">
              <flag style="font-size: 60px" iso="gb" />
            </v-avatar>
          </v-btn>
          <v-btn
            v-if="$i18n.locale === 'fr'"
            v-model="fab"
            fab
            icon
            dark
            small
            ripple
            class="ma-0"
            right
            depressed
            elevation="0"
          >
            <v-avatar size="28" class="mx-auto">
              <flag style="font-size: 60px" iso="fr" />
            </v-avatar>
          </v-btn>
        </template>
        <v-card color="primary" elevation="0" class="pt-3 pb-2 text-center">
          <v-btn
            v-if="$i18n.locale !== 'de'"
            @click="changeLocale('de')"
            fab
            icon
            dark
            ripple
            class="ma-0"
            small
            right
            depressed
            elevation="0"
          >
            <v-avatar size="34">
              <flag style="font-size: 58px" iso="de" />
            </v-avatar>
          </v-btn>
          <v-btn
            v-if="$i18n.locale !== 'en'"
            @click="changeLocale('en')"
            fab
            icon
            dark
            class="ma-0"
            small
            ripple
            right
            depressed
            elevation="0"
          >
            <v-avatar size="34">
              <flag style="font-size: 58px" iso="gb" />
            </v-avatar>
          </v-btn>
          <v-btn
            v-if="$i18n.locale !== 'fr'"
            @click="changeLocale('fr')"
            fab
            icon
            dark
            small
            ripple
            class="ma-0"
            right
            depressed
            elevation="0"
          >
            <v-avatar size="34">
              <flag style="font-size: 58px" iso="fr" />
            </v-avatar>
          </v-btn>
        </v-card>
      </v-speed-dial>

      <v-menu v-model="showUserOptions" close-on-content-click right nudge-bottom="45" >
        <template v-slot:activator="{ on }">
          <v-btn fab small text @click="openLoginOrOptions(on)" >
            <v-icon size="30px">mdi-menu</v-icon>
          </v-btn>
        </template>
        <v-list width="100%" dense>
          <v-list-item dense link @click="$router.push('/account')">
            <v-list-item-icon    >
              <v-icon color="blue lighten-4"> mdi-account-circle-outline </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              {{$t('delivery.lang_profile')}}
            </v-list-item-content>

          </v-list-item>
          <v-list-item @click="logout" dense>
            <v-list-item-icon    >
              <v-icon color="error"> mdi-logout </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              {{$t('generic.lang_logout')}}
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <!--end navbar-->

    <!--start sideBar of shopping cart-->
    <v-navigation-drawer
      :permanent="$vuetify.breakpoint.mdAndUp"
      v-if="
        this.$route.path !== '/order' &&
          this.$route.path !== '/info' &&
          this.$route.path !== '/account'
      "
      app
      :clipped="isFixedTop"
      right
      width="350px"
      color="grey lighten-3"
      class="mx-auto justify-center align-center"
      style="overflow-y: hidden;"
    >
      <!--28%-->
      <shopping-cart-pc />
    </v-navigation-drawer>
    <!--end sideBar of shopping cart-->

    <!--start authentification dialog-->
    <AuthDialogComponent v-if="dialog" v-model="dialog" :dialog="dialog" step="'welcome'"/>
    <!--end authentification dialog-->
  </div>
</template>

<script>
//import components
import ShoppingCartPc from "@/components/Card/ShoppingCartPc";
import Login from "@/components/Auth/Login.vue";
import Register from "@/components/Auth/Register.vue";
import ForgotPassword from "@/components/Auth/ForgotPassword.vue";
import SuccesAuth from "@/components/Auth/SuccesAuth.vue";
import { createNamespacedHelpers } from "vuex";
import AuthDialogComponent from "../Auth/AuthDialogComponent";
import { updateCurrencyFilter } from "../../plugins/currency";

export default {
  name: "Header",

  components: {
    AuthDialogComponent,
    ShoppingCartPc,
    Login,
    Register,
    ForgotPassword,
    SuccesAuth,
  },

  data: () => ({
    drawer: true,
    fab:false,
    isFixedTop: true,
    showUserOptions:false,
    dialog: false,
  }),

  computed: {
    step: {
      get() {
        return this.$store.getters["Config/authDlg"];
      },
      set(val) {
        this.$store.commit("Config/setAuthDlg", val);
      },
    },
    ...createNamespacedHelpers("auth").mapGetters(["isLoggedIn"]),
    ...createNamespacedHelpers("Config").mapGetters(["lang", "geoLoc", "defaultLang"]),
  },
  watch:{
    dialog(val){
      if(!val && this.step===5){
        this.step = 2;
      }else if(val && this.step===4){
        this.step = 2;
      }
    },
    defaultLang(val){
      this.changeLocale(val);
    },
    geoLoc(val){
      updateCurrencyFilter(this, val || 'de');
    }
  },
  methods: {
    changeLocale(locale) {
      if (locale === this.lang) return;
      this.$i18n.locale = locale;
      this.$axios.defaults.headers.common['language'] = locale;
      this.$store.commit("Config/setLanguage",locale);
      if(this.$route.name==="RestaurantMenu"){
        this.$store.dispatch("Config/loadStoreInfo");
        this.$store.dispatch("ItemGroups/loadItemGroups");
      }else if(this.$route.name==="AGB"||this.$route.name==="Datenschutz"||this.$route.name==="Impressum"){
        this.$store.dispatch("Config/loadStoreInfo");
      }
      this.$forceUpdate();
    },
    openLoginOrOptions(){
      if(this.isLoggedIn ){
        this.showUserOptions=true;
      }else{
        this.dialog=true;
      }
    },
    scrolled() {
      if (
        document.body.scrollTop > 250 ||
        document.documentElement.scrollTop > 250
      ) {
        this.isFixedTop = false;
      } else {
        this.isFixedTop = true;
      }
    },
    logout(){
      //this.logoutLoading=true;
      this.axios.post(`/localbee/v2/auth/logout`)
          .then(res=>{
            if(res && res.status===200){
              //set the auth data to null and loggedIn state to false
              this.$store.dispatch("auth/setAuthInfo", null).then(() => {
                this.axios.defaults.headers.common['Authorization'] = '';
                //when logout out redirect the user
                if(this.$route.name==='Account'){
                  this.$router.replace({
                    name: 'RestaurantMenu'
                  });
                }else if(this.$route.name==='CheckoutOrder'){
                  this.$router.go(0);
                }

              }).catch((err) => {
                console.log(err)
              });
            }
          }).catch(err=>{
        this.$swal({
          title: this.$t('delivery.lang_error'),
          icon: "error",
          text: err,
        });
      }).finally(()=>{
        //this.logoutLoading=false; // add this loading var to the loagout button's loading and diabled props to prevent multiple clicks
      })
    }
  },
  beforeDestroy() {
    this.$root.$off("showLogin")
  },
  mounted() {
    this.$root.$on("showLogin",this.openLoginOrOptions);
    updateCurrencyFilter(this, this.geoLoc);
  }
};
</script>

<style>
.v-speed-dial__list {
  padding: 0 !important;
}
</style>