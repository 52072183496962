<template>
  <div>
    <!--Start Footer-->
    <v-footer color="#272727">
      <v-container fill-height fluid grid-list-xs>
        <v-card
          tile
          elevation="0"
          color="transparent"
          :width="this.$vuetify.breakpoint.mdAndUp ? '90%' : '100%'"
          class="py-8 mx-auto text-center justify-center"
        >
          <v-row >
            <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3" align="center">
              <router-link to="/">
                <span class="white--text body-2">{{ $t('delivery.lang_home') }}</span>
              </router-link>
            </v-col>

            <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
              <router-link to="/impressum">
                <span class="white--text body-2">{{ $t('delivery.lang_imprint') }}</span>
              </router-link>
            </v-col>

            <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
              <router-link to="/datenschutz" class="white--text">
                <span class="white--text body-2">
                  {{$t('generic.lang_dataProtection')}}
                </span>
              </router-link>
            </v-col>

            <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
              <router-link to="/agb" class="white--text">
                <span class="white--text body-2">{{ $t("delivery.lang_conditions") }}</span>
              </router-link>
            </v-col>

            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
              <v-card
                tile
                elevation="0"
                color="transparent"
                width="600px"
                class="d-flex text-center mx-auto justify-center"
                style="position: relative; top: 30px;"
              >
                <div>
                  <span class="grey--text body-2">
                    {{ $t('delivery.lang_madeWithPassion') }} & <v-icon color="red" size="16px">mdi-heart</v-icon> {{ $t('delivery.lang_by3pos') }} <v-icon color="grey" size="16px">mdi-copyright</v-icon>
                  </span>

                  <span class="grey--text body-2">
                    {{ getYear }}
                  </span>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-container>
    </v-footer>
    <!--End Footer-->
  </div>
</template>

<script>
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {library} from "@fortawesome/fontawesome-svg-core";
import {faGlobe} from "@fortawesome/pro-light-svg-icons";
import moment from "moment";

library.add({
  faGlobe,
})

export default {
  name: "Footer",
  components:{
    'font-awesome-icon':FontAwesomeIcon
  },
  computed:{
    getYear(){
      return moment().format('YYYY')
    }
  },
};
</script>
